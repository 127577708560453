@tailwind base;
@tailwind components;
@tailwind utilities;

#root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
}